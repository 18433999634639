
export const BASE_MENU: Object = [ ];

export class SubMenuModel {
  code: string;
  info: string;
  path: string;
  active: boolean;
  select: boolean;

  constructor() {
    this.active = false;
    this.select = false;
  }
}

export class MenuModel {
  code: string;
  info: string;
  path: string;
  active: boolean;
  select: boolean;
  child?: (SubMenuModel)[] | null;

  constructor() {
    this.active = false;
    this.select = false;
    this.info = "Dashboard";
    this.child = null;
  }
}

export interface ResponseModel {
  status:  boolean,
  code:    string,
  message: string,
  data:    object,
}
