import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env';

// Import RxJs required methods
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ResponseModel } from '@shared/models';

@Injectable()
export class AdapterService {
  private uri: string;
  private key: string;

  constructor(
    private http: HttpClient
  ) {
    this.uri = environment.api.url;
    this.key = environment.api.key;
  }

  onHeader(): HttpHeaders {
    return new HttpHeaders({
      'Accept': '*/*',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'api-key': this.key,
    });
  }

  onError(error: HttpErrorResponse) {
    let message = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      message = `Client: ${error.error.message}`;
    } else {
      // Server-side errors
      let msg = (error.status === 0) ? message : error.error.message;
      message = `Server: [${error.status}] ${msg}`;
    }
    return throwError(message);
  }

  // Send message on GET
  public getData(urn: string): Observable<any> {
    return this.http.get(`${this.uri}/${urn}`, { headers: this.onHeader() })
      .pipe(map((ret:ResponseModel) => ret.data), catchError(this.onError));
  }
  // Send message on PUT
  public putData(urn: string, data: any): Observable<any> {
    return this.http.put(`${this.uri}/${urn}`, data, { headers: this.onHeader() })
      .pipe(map((ret:ResponseModel) => ret.data), catchError(this.onError));
  }
  // Send message on POST
  public postData(urn: string, data: any): Observable<any> {
    return this.http.post(`${this.uri}/${urn}`, data, { headers: this.onHeader() })
      .pipe(map((ret:ResponseModel) => ret.data), catchError(this.onError));
  }
  // Send message on DELETE
  public deleteData(urn: string): Observable<any> {
    return this.http.delete(`${this.uri}/${urn}`, { headers: this.onHeader() })
      .pipe(map((ret:ResponseModel) => ret.data), catchError(this.onError));
  }
  // Send message on OPTIONS
  public optionsData(urn : string): Observable<any> {
    return this.http.options(`${this.uri}/${urn}`, { headers: this.onHeader() })
      .pipe(map((ret:ResponseModel) => ret.data), catchError(this.onError));
  }
  /*
  // Send message on GET with multiple data
  public getMultiData(urn: string[]): Observable<any> {
    if (urn.length !== 2)
      return;
    let firstResponse = this.http.get(`${this.uri}/${urn[0]}`);
    let secondResponse = this.http.get(`${this.uri}/${urn[1]}`);
    return forkJoin([firstResponse, secondResponse]);
  }
  // Send message on GET and convert response to Blob
  public getBlobData(urn: string): Observable<any> {
      return this.http.get(`/${this.uri}/${urn}`, { responseType: 'blob' });
  }
  */
}
