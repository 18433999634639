import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class StorageService {

  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage
  ) { }

  // On upload the file
  public upload(path: string, file: any): Observable<number> {
    let refer = this.storage.upload(path, file);
    return refer.percentageChanges();
  }

  // On download the file
  public download(path: string): Observable<any> {
    let refer = this.storage.ref(path);
    return refer.getDownloadURL();
  }

  // On download to blob
  public getToBlob(path: string): Promise<Blob> {
    let refer = this.storage.ref(path).getDownloadURL().toPromise();
    return refer.then(url => fetch(url).then(ret => ret.blob()));
  }
}
