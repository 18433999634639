import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';

@Injectable()
export class JiraService {
  private api: string = 'jira';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public setIssue(data: object): Observable<any> {
    return this.adapter.postData(`${this.api}/create-issue`, data).pipe(map((ret) => ret));
  }
}