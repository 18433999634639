import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { BASE_MENU, MenuModel } from '@shared/models';

@Injectable()
export class CommonService {
  private menuList: Array<MenuModel>;

  constructor(
    private config: ConfigService,
    private translate: TranslateService
  ) {
    if (this.menuList === undefined) {
      this.initMenu();
    }
  }

  get isEN(): boolean {
    return (this.translate.currentLang != 'th');
  }

  get locale(): string {
    return this.translate.currentLang;
  }

  get allMenu(): Array<MenuModel> {
    return this.menuList;
  }

  private initMenu() {
    this.menuList = BASE_MENU as Array<MenuModel>;
  }

  public getOnMenu(code: string): MenuModel {
    let menu = this.menuList.find(obj => obj.code === code);
    return menu;
  }

  public defineMenu(): Observable<boolean> {
    return Observable.create(observer => {
      // TODO: Get user permission
      //
      // Set mapping menu with permission
      this.menuList = this.menuList.map((menu) => {
        menu.active = true;
        menu.select = false;
        if (menu.child != undefined) {
          menu.child = menu.child.map((child) => {
            child.active = true;
            child.select = false;
            return child;
          });
        }
        return menu;
      });
      observer.next(true);
      observer.complete();
    });
  }
}
