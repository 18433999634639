export const environment = {
  production: true,
  version: '1.0.0',
  urlLogin: 'https://lenderportal-prd.web.app/',
  api: {
    env: 'product',
    url: 'https://asia-southeast2-chessmoney-prd.cloudfunctions.net',
    key: 'k8eiAZAWoc8mbWH3R09i'
  },
  firebase: {
    apiKey: "AIzaSyAabE4eXMuq5f59EXWQRSgVQzx9mWTlz5Q",
    authDomain: "chessmoney-prd.firebaseapp.com",
    databaseURL: "https://chessmoney-prd.firebaseio.com",
    projectId: "chessmoney-prd",
    storageBucket: "chessmoney-prd.appspot.com",
    messagingSenderId: "940723858598",
    appId: "1:940723858598:web:63e4111ee93f59effa9867",
    measurementId: "G-ZGM9GX0DMM"
  }
};
