import { NgModule } from '@angular/core';
import { LocationStrategy, Location, PathLocationStrategy, HashLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env';

import { TranslatorPipe } from '@shared/pipes';
import {
  AdapterService,
  AuthenService,
  CommonService,
  ConfigService,
  JiraService,
  StorageService
} from '@shared/services';

import { AppComponent } from './app.component';

const BASE_ROUTES: Routes = [{
  path: '', redirectTo: 'home', pathMatch: 'full'
}, {
  path: 'void', component: AppComponent
}, {
  path: 'home',
  loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
}, {
  path: '404',
  loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule)
}, {
  path: '**', redirectTo: '404'
}];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase, "cloud"),
    // Note: remove hash to useHash is false or not include
    RouterModule.forRoot(BASE_ROUTES, { useHash: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    NgbModule
  ],
  providers: [
    Location,
    { provide: APP_BASE_HREF, useValue: '/' },
    // Note: remove hash to useClass is PathLocationStrategy
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AdapterService,
    AuthenService,
    CommonService,
    ConfigService,
    JiraService,
    StorageService
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
