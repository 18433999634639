import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { get, isEmpty } from 'lodash-es';
import { environment } from '@env';

import { ConfigService } from './config.service';
import { AdapterService } from './adapter.service';
import { SignInModel, SignUpModel, TokenModel } from '@shared/models';

@Injectable()
export class AuthenService {
  private temp: Array<SignInModel>;
  private model: SignInModel;
  private api: string = 'auth';

  constructor(
    private config: ConfigService,
    private router: Router,
    private adapter: AdapterService,
  ) {
    this.temp = this.config.getConfig('temp.acc');
  }

  public access(username: string, password: string): Promise<boolean> {
    // This temporary 'GET' api
    return this.adapter.getData(this.api)
      .toPromise()
      .then(response => {
        const token = get(response, 'access_token', '');
        const admin = get(response, 'is_admin', '');
        localStorage.setItem('username', username);
        localStorage.setItem('access_token', token);
        localStorage.setItem('is_admin', admin);
        return true;
      })
      .catch(error => {
        console.log(error);
        return false;
      });
  }

  public register(data: SignUpModel): Observable<TokenModel> {
    return this.adapter.postData(`${this.api}/signup`, data).pipe(map((ret:TokenModel) => ret));
  }

  public getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    const isExist = !isEmpty(accessToken);
    return { isExist, accessToken };
  }

  public logout(): void {
    localStorage.removeItem('activate');
    localStorage.removeItem('username');
    localStorage.removeItem('api_key');
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }

  public isAuthorized(): boolean {
    return this.getAccessToken().isExist;
  }

  public isAdmin(): boolean {
    const isAdmin = localStorage.getItem('is_admin');
    console.log(isAdmin);
    return false;
  }
}
